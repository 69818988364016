<template>
  <Wrapper>
    <section class="hero">
      <div class="hero-body">
        <h1 class="title">Seja bem-vindo(a) a Kamino!</h1>
        <p class="mb-3">
          Estamos muito felizes que vá acelerar o seu negócio conosco,
          agradecemos a confiança!
        </p>
        <p class="mb-3">
          Nesta jornada vamos te ajudar a estruturar a sua empresa
          <span class="is-italic">offshore</span>, abrir as contas no
          <span class="is-italic">Silicon Valley Bank</span> e fazer a
          contabilidade nos Estados Unidos. Não se preocupe, temos parceiros
          especializados para tudo isso e vamos caminhar juntos ao longo do
          processo.
        </p>
        <p class="mb-3">O que esperar da sua jornada:</p>
        <b-image
          class="mb-5"
          :src="require('@/assets/image/offshore_process.svg')"
          alt="Etapas do processo offshore"
          ratio="17by5"
        />
        <p class="mb-3">
          Para uma experiência ótima, utilize o seu navegador para preencher o
          formulário seguinte.
        </p>
        <p class="mb-3" v-if="buttonLabel === 'Iniciar'">
          Ao clicar em "Iniciar" você aceita e concorda com os
          <a
            class="is-underlined"
            href="https://kamino.com.br/general-terms-and-conditions/"
            target="_blank"
            >Termos e Condições de Uso</a
          >
          e a
          <a
            class="is-underlined"
            href="https://kamino.com.br/privacy-policy/"
            target="_blank"
            >Política de Privacidade</a
          >
          da Kamino.
        </p>
      </div>
    </section>

    <div class="columns">
      <div class="column"></div>
      <div class="column is-one-quarter pl-5 pr-5">
        <b-button expanded type="is-link" @click="onStartProcess">
          {{ buttonLabel }}
        </b-button>
      </div>
      <div class="column"></div>
    </div>
  </Wrapper>
</template>

<script>
import { Wrapper } from '@/components/Wrapper';
import { customerStorage } from '@/store';

export default {
  name: 'HomeView',
  components: {
    Wrapper,
  },
  data() {
    return {
      buttonLabel: '',
    };
  },
  beforeMount() {
    const customer = customerStorage.getCustomer();
    this.buttonLabel = customer?.registerStatus ? 'Continuar' : 'Iniciar';
  },
  methods: {
    onStartProcess() {
      this.$router.push('/form');
    },
  },
};
</script>
